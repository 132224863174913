// change the theme
$theme-colors: (
  "dark": #2d0635,
  "light": #eeeeee,
  "primary-dark": #d60cff,
  "primary-light": #220027,
  "secondary-dark": #00ff4c,
  "secondary-light": #002500,
  "text-dark": #eeeeee,
  "text-light": #2d0635,
  "link-dark": #5900ff,
  "link-light": #0400ff,
  // Text highlight colours
  "dark-c1": #eeaa00,
  "dark-c2": #eeaaff,
  "dark-c3": #ff6262,
  "dark-kanji": #8448bd,
  "dark-dim1": #ababab,
  "light-c1": #992299,
  "light-c2": #0a9dc2,
  "light-c3": #c70000,
  "light-kanji": #3a2180,
  "light-dim1": #787878,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.main-page {
  padding: 0px 40px 0px 40px;
}

body.dark {
  background-color: map-get($theme-colors, "dark") !important;
}

body.light {
  background-color: map-get($theme-colors, "light") !important;
}

.text-dark {
  color: map-get($theme-colors, "text-dark") !important;
}

.text-light {
  color: map-get($theme-colors, "text-light") !important;
}

.link-dark {
  color: map-get($theme-colors, "link-dark") !important;

  &:hover {
    color: map-get($theme-colors, "link-dark") !important;
    opacity: 0.5;
  }
}

.link-light {
  color: map-get($theme-colors, "link-light") !important;

  &:hover {
    color: map-get($theme-colors, "link-light") !important;
    opacity: 0.5;
  }
}

.table-dialog tr:nth-child(even) {
  background-color: map-get($theme-colors, "light");
  color: map-get($theme-colors, "text-light");
}

.table-dialog tr:nth-child(odd) {
  background-color: map-get($theme-colors, "dark");
  color: map-get($theme-colors, "text-dark");
}

.table-fielddays {
  font-size: small;
  white-space: nowrap;
  margin-bottom: 10px;
}

.table-fielddays td {
  padding: 5px;
  border: 1px solid;
  border-color: black;
}

.table-fielddays th {
  padding: 5px;
  border: 1px solid;
  border-color: black;
  background-color: burlywood;
}

.table-fielddays tr:hover {
  background-color: rgb(236, 221, 221);
}

.container-map {
  width: 65%;
}

.breadcrumbs a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.courses-card {
  max-width: 18rem;
  margin: 5px;
  display: inline-block;
}

.code-block-dark {
  border: #424242 1px solid;
  background-color: #242424;

  & .code-block-header {
    background-color: #1a1a1a;
    border-bottom: #727272 1px solid;
    position: relative;
    height: 30px;

    & .code-block-copy-button {
      border-radius: 5px;
      border: 1px solid #000;
      color: #dadada;
      background-color: #0f0f1b;
      padding: 2px 4px;
      font-size: 10px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.code-block-light {
  border: #727272 1px solid;
  background-color: #e0e0e0;

  & .code-block-header {
    background-color: #cfcfcf;
    border-bottom: #727272 1px solid;
    position: relative;
    height: 30px;

    & .code-block-copy-button {
      border-radius: 5px;
      border: 1px solid #cccccc;
      color: #0f0f0f;
      background-color: #f2f2ff;
      padding: 2px 4px;
      font-size: 10px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.jsp {
  // Dark theme.
  &.dark {
    &.c1 {
      color: map-get($theme-colors, "dark-c1")
    }
    &.c2 {
      color: map-get($theme-colors, "dark-c2")
    }
    &.c3 {
      color: map-get($theme-colors, "dark-c3")
    }
    &.dim1 {
      color: map-get($theme-colors, "dark-dim1");
    }
    &.s {
      text-decoration: line-through;
    }
    &.u {
      text-decoration: underline;
    }
  }

  // Light theme.
  &.light {
    &.c1 {
      color: map-get($theme-colors, "light-c1")
    }
    &.c2 {
      color: map-get($theme-colors, "light-c2");
    }
    &.c3 {
      color: map-get($theme-colors, "light-c3")
    }
    &.dim1 {
      color: map-get($theme-colors, "light-dim1");
    }
    &.s {
      text-decoration: line-through;
    }
    &.u {
      text-decoration: underline;
    }
  }
}

.dark-kanji {
  color: map-get($theme-colors, "dark-kanji") !important;
}

.light-kanji {
  color: map-get($theme-colors, "light-kanji") !important;
}

li.hidden {
  display: none;
}

// Core variables and mixins
// @import "variables";
// @import "mixins";